// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-agrotech-index-jsx": () => import("./../../../src/pages/agrotech/index.jsx" /* webpackChunkName: "component---src-pages-agrotech-index-jsx" */),
  "component---src-pages-avl-index-jsx": () => import("./../../../src/pages/avl/index.jsx" /* webpackChunkName: "component---src-pages-avl-index-jsx" */),
  "component---src-pages-gestion-index-jsx": () => import("./../../../src/pages/gestion/index.jsx" /* webpackChunkName: "component---src-pages-gestion-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nosotros-index-jsx": () => import("./../../../src/pages/nosotros/index.jsx" /* webpackChunkName: "component---src-pages-nosotros-index-jsx" */),
  "component---src-pages-privacidad-index-jsx": () => import("./../../../src/pages/privacidad/index.jsx" /* webpackChunkName: "component---src-pages-privacidad-index-jsx" */),
  "component---src-pages-smart-cities-index-jsx": () => import("./../../../src/pages/smart-cities/index.jsx" /* webpackChunkName: "component---src-pages-smart-cities-index-jsx" */),
  "component---src-pages-transporte-index-jsx": () => import("./../../../src/pages/transporte/index.jsx" /* webpackChunkName: "component---src-pages-transporte-index-jsx" */),
  "component---src-pages-ultima-milla-index-jsx": () => import("./../../../src/pages/ultima_milla/index.jsx" /* webpackChunkName: "component---src-pages-ultima-milla-index-jsx" */)
}

